<template>
  <div id="misc">
    <img
      class="misc-mask"
      height="226"
      :src="require(`@/assets/images/misc/misc-mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />

    <v-img
      class="misc-tree"
      src="@/assets/images/misc/tree.png"
    ></v-img>

    <div class="page-title px-5">
      <v-row justify="center"
             align="center" class="fill-height">
        <v-col cols="12" class="text-center ">
          <v-img
            :src="appLogo"
            max-height="200px"
            max-width="200px"
            alt="logo"
            contain
            class="mx-auto "
          ></v-img>

          <h2 class=" display-2 mt-3 font-weight-semibold">
            {{ appName }}
          </h2>
        </v-col>
        <v-col cols="12">
          <analytics-shop-statistics-card />
        </v-col>
      </v-row>

<!--
      <div class="misc-character d-flex justify-center">
        <v-img
          max-width="700"
          src="@/assets/images/3d-characters/under-maintenance.png"
        ></v-img>
      </div>
-->

      <!--      <v-btn
        color="primary"
        to="/"
        class="mb-4"
      >
        Retour à l'accueil
      </v-btn>-->
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiTrendingUp,
  mdiCurrencyUsd,
  mdiCheck,
  mdiChartTimelineVariant,
  mdiChevronUp,
  mdiPlus
} from '@mdi/js'
import themeConfig from '@themeConfig'
import AnalyticsStatisticsCard from "@/views/dashboards/analytics/AnalyticsStatisticsCard";
import AnalyticsShopStatisticsCard from "@/components/AnalyticsShopStatisticsCard";

export default {
  components: {AnalyticsShopStatisticsCard, AnalyticsStatisticsCard},
  setup() {
    const transactionsOptions = {
      statTitle: 'Transactions',
      icon: mdiTrendingUp,
      color: 'info',
      subtitle: 'Daily Transactions',
      statistics: '1.2k',
      change: '+12%',
    }
    const revenueOptions = {
      statTitle: 'Revenue',
      icon: mdiCurrencyUsd,
      color: 'success',
      subtitle: 'Revenue Increase',
      statistics: '$95.2k',
      change: '+12%',
    }

    const revenueLineChart = {
      statTitle: 'Total Revenue',
      statistics: '$35.4k',
      series: [
        {
          data: [28, 58, 40, 68],
        },
      ],
    }

    const salesRadialChart = {
      statTitle: 'Total Sales',
      statistics: '135k',
      series: [78],
    }

    const logisticsOptions = {
      statTitle: 'Logistics',
      icon: mdiChartTimelineVariant,
      color: 'error',
      subtitle: 'Revenue Increase',
      statistics: '44.1k',
      change: '+12%',
    }

    const reportsOptions = {
      statTitle: 'Reports',
      icon: mdiCheck,
      color: 'warning',
      subtitle: 'System Bugs',
      statistics: '268',
      change: '-8%',
    }

    return {
      transactionsOptions,
      revenueOptions,
      revenueLineChart,
      salesRadialChart,
      logisticsOptions,
      reportsOptions,
      icons: {
        mdiChevronUp,
        mdiPlus,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/misc.scss';
</style>
