<template>
  <v-card :loading="loading">
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ $t("Statistics") }}</span>
      <v-spacer></v-spacer>
      <!--      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>-->
    </v-card-title>

    <!--    <v-card-subtitle class="mb-8 mt-n5">
      <span class="font-weight-semibold text&#45;&#45;primary me-1">Total 48.5% Growth</span>
      <span>😎 this month</span>
    </v-card-subtitle>-->

    <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.code"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.code).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.code).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
               {{ kFormatter(data.total)}}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'

import { onUnmounted, ref, watch } from '@vue/composition-api'
import { getIndex } from '@core/utils'
import store from '@/store'
import router from '@/router'
import { i18n } from '@/plugins/i18n'
import axios from "@axios";

export default {
  setup() {
    const statisticsData = ref([
      {
        title: i18n.t('Sales'),
        code: 'Sales',
        total: 0,
      },
      {
        title: i18n.t('Customers'),
        code: 'Customers',
        total: 0,
      },
      {
        title: i18n.t('Product'),
        code: 'Product',
        total: 0,
      },
      {
        title: i18n.t('Revenue'),
        code: 'Revenue',
        total: 0,
      },
    ])
    const loading = ref(true)

    const resolveStatisticsIconVariation = data => {
      if (data === 'Sales') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Customers') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    axios.get('/shop/statistics').then((response) => {
      loading.value = false
      console.log(response.data)
      statisticsData.value[0].total = response.data.sales || 0
      statisticsData.value[1].total = response.data.customers || 0
      statisticsData.value[2].total = response.data.products || 0
      statisticsData.value[3].total = response.data.revenue || 0


    })

    return {
      loading,
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
